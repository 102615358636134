import {captureMessage} from '@sentry/gatsby';
import * as api from './api';
import {ARHIJoinState} from '../rootReducer';
import {
  fetchPricePending,
  fetchPriceSuccess,
  fetchPriceRejected,
  PriceResult,
  setPricingTriggerFields,
  fetchProductPricesPending,
  fetchProductPricesRejected,
  fetchFuturePricePending,
  fetchFuturePriceRejected,
  fetchFuturePriceSuccess,
  batchFetchProductPricesSuccess
} from './pricingSlice';
import {AxiosResponse, AxiosError, isAxiosError} from '../types';
import {mapSessionToPriceRequest} from './mappers';
import {getPricingProducts, getTotalPrice} from './selectors';
import {PricingWatchedValues} from '../session/selectors/pricing';
import {ProductTypes} from './types';
import {isPriceFactorInState, productParamsByProductType} from './utils';
import {getCoverStartDate} from '../session/selectors/financialDetails';

import {ProductExcesses} from '../../hooks/UseProductExcessesStatic';
import {selectShowArhiPPCInformation} from '../featureToggles/selectors';
import {isStringDateBefore} from '../../services/utils';
import {getSessionId, getSessionState} from '../session/selectors';

const logFetchPriceError = (result: AxiosResponse<PriceResult> | AxiosResponse<AxiosError>) => {
  captureMessage(`Price fetch error -- ${JSON.stringify(result)}`, 'error');
};

const logFetchFuturePriceError = (result: AxiosResponse<PriceResult> | AxiosResponse<AxiosError>) => {
  captureMessage(`Future price fetch error -- ${JSON.stringify(result)}`, 'error');
};

export const fetchTotalPrice =
  () =>
  async (dispatch, getState): Promise<void> => {
    const state: ARHIJoinState = getState();
    const reduxSession = getSessionState(state);
    const sessionId = getSessionId(state);

    if (sessionId && reduxSession) {
      const params = mapSessionToPriceRequest(state);

      // Don't try to fetch price if no products are selected
      if (!(params.hospitalProduct || params.extrasProduct)) {
        return;
      }
      dispatch(fetchPricePending());
      try {
        const priceResult: AxiosResponse<PriceResult> | AxiosResponse<AxiosError> = await api.fetch(params);
        if (isAxiosError(priceResult)) {
          logFetchPriceError(priceResult);
          dispatch(fetchPriceRejected({error: priceResult.data}));
        } else {
          dispatch(fetchPriceSuccess(priceResult.data));
        }
        return;
      } catch (exception) {
        dispatch(fetchPriceRejected({error: exception}));
        return;
      }
    } else {
      const error = 'fetchPrice was called but no valid session exists in cookie.';
      captureMessage(error);
    }
  };

export const fetchFuturePrice =
  (ppcDate: string) =>
  async (dispatch, getState): Promise<void> => {
    const state: ARHIJoinState = getState();
    const reduxSession = getSessionState(state);
    const sessionId = getSessionId(state);

    if (sessionId && reduxSession) {
      // Adds PPC date to conditionally use it
      const params = mapSessionToPriceRequest(state, ppcDate);
      // Don't try to fetch price if no products are selected
      if (!(params.hospitalProduct || params.extrasProduct)) {
        return;
      }
      dispatch(fetchFuturePricePending(params));
      try {
        const priceResult: AxiosResponse<PriceResult> | AxiosResponse<AxiosError> = await api.fetch(params);
        if (isAxiosError(priceResult)) {
          logFetchFuturePriceError(priceResult);
          dispatch(fetchFuturePriceRejected({error: priceResult.data}));
        } else {
          dispatch(fetchFuturePriceSuccess(priceResult.data));
        }
        return;
      } catch (exception) {
        dispatch(fetchFuturePriceRejected({error: exception}));
        return;
      }
    } else {
      // This may not necessarily be an error, so will not dispatch a rejection action
      const error = 'fetchFuturePrice was called but no valid session exists in cookie.';
      captureMessage(error);
    }
  };

export const fetchPriceAndUpdateNotifications = (current: PricingWatchedValues, previous: PricingWatchedValues) => async (dispatch, getState) => {
  const previousTotalPrice = getTotalPrice(getState());

  // Covers initial fetch and price changes based off fields.
  await dispatch(fetchTotalPrice());

  // Update pricingTriggerFields only if price has changed.
  const newTotalPrice = getTotalPrice(getState());
  const hasPriceChanged = previousTotalPrice ? previousTotalPrice !== newTotalPrice : false;
  if (hasPriceChanged) {
    const changedTriggerFields = Object.keys(current).filter((k) => current[k] !== previous[k]);
    dispatch(setPricingTriggerFields(changedTriggerFields));
  }
};

export const fetchProductPrices =
  (productIds: number[], productType: ProductTypes, productExcesses: ProductExcesses[] = [], ppcDate: string) =>
  async (dispatch, getState): Promise<void> => {
    const state: ARHIJoinState = getState();
    const reduxSession = getSessionState(state);
    const sessionId = getSessionId(state);
    const params = mapSessionToPriceRequest(state);
    const productPrices = getPricingProducts(state);

    // Dual pricing
    const showArhiPpcInfo = selectShowArhiPPCInformation(state);
    const fetchFuturePricing = showArhiPpcInfo && isStringDateBefore(getCoverStartDate(state), ppcDate);

    // Filter out the products which do not exist for the currently selected excess
    if (productExcesses.length) {
      const sessionExcess = params.excess;
      productIds = productIds.filter((pId) => productExcesses.find((pe) => pe.pcatId === pId)?.currentExcesses.includes(sessionExcess));
    }

    // Filter out products where we already have price
    const productsToFetchPrice = productIds.filter((id) => {
      const productParams = productParamsByProductType(productType, id, params);
      return !isPriceFactorInState(productPrices[id]?.pricesByFactors, productParams);
    });

    if (sessionId && reduxSession && productsToFetchPrice.length) {
      try {
        const productParams: any = {...params};
        productParams.products = productsToFetchPrice;
        productParams.productType = productType;
        dispatch(fetchProductPricesPending(productParams));
        const prices = await api.batchFetchPrices(productParams);
        if (isAxiosError(prices)) {
          dispatch(fetchProductPricesRejected({error: prices.data}));
          logFetchPriceError(prices);
          return;
        }

        const data = prices.data;

        // Fetch future (PPC) price if in pre-PPC period
        if (fetchFuturePricing) {
          const futurePriceParams = {...productParams, firstPaymentDate: ppcDate, effectiveDate: ppcDate};
          const futurePrices = await api.batchFetchPrices(futurePriceParams);
          if (!isAxiosError(futurePrices)) {
            futurePrices.data.data.forEach((fp) => {
              const index = data.data.findIndex((p) => p[productType].id === fp[productType].id);
              if (index >= 0) {
                data.data[index][productType].futurePrice = fp.total;
              }
            });
          }
        }

        const pricingParams = {...params};
        pricingParams.extrasProduct = null;
        pricingParams.hospitalProduct = null;

        if (prices.data.data.length > 0) {
          // If we're fetching extras pricing after having previously failed to fetch hospital pricing then totalPricing
          // will be unset. Request a re-fetch of the total price to refresh UI in case service errors have since resolved.
          if (productType === 'extras') {
            const previousTotalPrice = getTotalPrice(getState());
            if (previousTotalPrice === 0) await dispatch(fetchTotalPrice());
          }

          dispatch(batchFetchProductPricesSuccess({productPriceResults: prices.data, errors: prices.data.errors, productType, pricingParams}));
        } else {
          dispatch(fetchProductPricesRejected({error: prices.data.errors}));
        }
      } catch (exception) {
        dispatch(fetchProductPricesRejected({error: exception}));
        return exception;
      }
    }
  };
