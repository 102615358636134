import {FormikProps} from 'formik';
import {WelcomeFieldLabels} from '../../../hooks/UseWelcomeStatic';
import {ResidentTypeGroupProps} from './ResidentTypeGroup/types';
import {WelcomeDetailsValues} from './types';
import {Funds} from '@nib/phi-constants';

export const FIELD_NAMES = {
  DATE_OF_BIRTH: 'dateOfBirth',
  FIRST_NAME: 'firstName',
  PARTNER_DATE_OF_BIRTH: 'partnerDateOfBirth',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  SCALE: 'scale',
  COVER_START_DATE: 'coverStartDate',
  RESIDENT_TYPE: 'residentType',
  VISA_SUBCLASS: 'visaSubclass',
  VISITOR_TYPE: 'visitorType'
} as const;

export const FIELD_LABELS = {
  COVER_START_DATE: 'When would you like your cover to start?',
  VISITOR_TYPE: 'Which option best describes you?'
};

export const RESIDENT_TYPES = {
  [Funds.ARHI]: 'Australian resident',
  [Funds.IWHI]: 'Overseas worker',
  [Funds.ISHI]: 'Overseas student'
};

export const getResidentTypeGroupProps = (formikBag: FormikProps<WelcomeDetailsValues>, fieldLabels: WelcomeFieldLabels): ResidentTypeGroupProps => {
  return {
    values: {
      residentType: formikBag.values.residentType,
      visaSubclass: formikBag.values.visaSubclass
    },
    residentTypeId: FIELD_NAMES.RESIDENT_TYPE,
    residentTypeName: FIELD_NAMES.RESIDENT_TYPE,
    visaSubclassId: FIELD_NAMES.VISA_SUBCLASS,
    visaSubclassName: FIELD_NAMES.VISA_SUBCLASS,
    residentTypeLabel: fieldLabels.residentType,
    visaSubclassLabel: fieldLabels.visaSubclass
  };
};

export const visaSubClasses = [
  {value: '408', text: '408 - Temporary Activity'},
  {value: '457', text: '457 - Temporary Work (Skilled) Visa'},
  {value: '482', text: '482 - Temporary Skill Shortage (TSS) Visa'},
  {value: '485', text: '485 - Temporary Graduate'},
  {value: '500', text: '500 - Student Visa'},
  {value: 'Other', text: 'Other'}
];

export const VISITOR_TYPES = {
  NewToPHI: 'New to health insurance',
  Switcher: 'Switching health insurers',
  Customer: 'Current nib member'
};
