import {getDefaults} from '@nib/phi-constants';
import {isProd} from './services/sharedUtils';
import {FUNNEL_NAME} from './services/utils';
import type {ARHIFunnelPage, ARHIPage} from './types/pages';

// if local, just use kaos master. Otherwise lets use join-service sitting behind the www router
const apiBasePath = `${process.env.GATSBY_JOIN_SERVICES_API_PREFIX}`;

export const pricingApiBasePath = process.env.GATSBY_PRICING_API_URL;

export const hspConfigurationUrl = process.env.GATSBY_HSP_CONFIGURATION_URL;

export const effectiveProductDateOverride = process.env.GATSBY_PRODUCT_EFFECTIVE_DATE_OVERRIDE;

export const featureToggleApiUrl = `${process.env.GATSBY_APPS_CONFIG_URL}/v1/feature-toggles`;

export const BraintreeClientTokenApiUrl = process.env.GATSBY_BRAINTREE_CLIENT_TOKEN_API_URL;

export const BraintreeClientTokenApiKey = process.env.GATSBY_BRAINTREE_CLIENT_TOKEN_API_KEY;

//Here for buildkite mocking of current date, not used atm
export const mockCurrentDateEnv = process.env.GATSBY_MOCK_CURRENT_DATE_ENV;

export const MOCK_CURRENT_DATE_COOKIE = 'mock-current-date';

export const NOT_FOUND_ERROR = '/404';

export const TRACE_ID_COOKIE = 'trace_id';

export const CUSTOMER_COOKIE = 'Customer';

export const SESSION_COOKIE_VERSION = 12;

export const SESSION_NULL_ID = '00000000-0000-0000-0000-000000000000';

export const RATE_CODE_COOKIE = 'RateCode';

export const PRICING_CALC_COOKIE = 'PricingCalc';

export const DEFAULT_RATE_CODE = 'I78';

export const SessionURLV2 = `${apiBasePath}/v2/session`;

export const SessionURLV1 = `${apiBasePath}/v1/session`;

export const InitialiseSessionURL = `${apiBasePath}/v1/arhi/session/init`;

export const JoinURL = `${apiBasePath}/v1/join/arhi`;

export const PricingURL = `${pricingApiBasePath}/v1/australian-resident`;

export const PreviousFundsUrl = `${apiBasePath}/v1/previous-funds/arhi`;

export const CampaignUrl = `${apiBasePath}/v3/campaign`;

export const SendQuoteUrl = `${apiBasePath}/v1/prospect/quote/`;

export const ResumeQuoteUrl = `${apiBasePath}/v1/prospect/resume/`;

export const LookupUrl = `${apiBasePath}/v1/lookup`;

export const NIB_CONTACT_LINK = '/contact-us';

export const invalidResponseMessage = "We're experiencing a technical issue right now.";

export const noPriceMessage = "We're unable to load your prices right now.";

export const MEDICARE_GENDER_LINK = 'https://www.humanservices.gov.au/individuals/topics/updating-your-gender-details-us/34751';

export const HUMAN_SERVICES_PRIVACY_LINK = 'https://www.humanservices.gov.au/privacy';

export const AAMI_HOMEPAGE_URL = 'https://www.aami.com.au/health-insurance.html';

export const SUNCORP_HOMEPAGE_URL = 'https://www.suncorp.com.au/insurance/health.html';

export const PRICELINE_HOMEPAGE_URL = 'https://www.priceline.com.au/health-insurance';

export const SENIORS_HOMEPAGE_URL = 'https://www.seniors.com.au/';

export const REAL_HOMEPAGE_URL = 'https://www.realinsurance.com.au/';

// baseAbsoluteURL passed to header component, it will add trailing slash for resource suffix
export const NIB_HOMEPAGE_URL = isProd() ? 'https://www.nib.com.au' : 'https://www.nib-cf-test.com';

export const APIA_HOMEPAGE_URL = 'https://www.apia.com.au/health-insurance.html';

export const ING_HOMEPAGE_URL = 'https://www.ing.com.au/';

export const KLEBER_API_KEY = 'RK-ED50D-282F9-D3A67-AF6FF-9A112-CAA2D-C8CC9-9F550';

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

export const PriceNotFoundError = 'Could not find hospitalProduct WHICS code.';

export const isSendQuoteEnabled = process.env.GATSBY_ENABLE_SEND_QUOTE === '1';

export const OptOutPPC = process.env.GATSBY_OPT_OUT_PPC === '1';

export const DENTAL_PASS_HOME = isProd() ? 'https://www.nib.com.au/wellbeing/dentalpass' : 'https://www.nib-cf-test.com/wellbeing/dentalpass';

export const DENTAL_PASS_FIND_PROVIDER = isProd() ? 'https://www.nib.com.au/wellbeing/find-a-provider' : 'https://www.nib-cf-test.com/wellbeing/find-a-provider';

export const CORPORATE_HOME = isProd() ? 'http://corporate.nib.com.au/join/corporate-landing' : 'https://corporate.nib-cf-test.com/join/corporate-landing';

export const GUHEALTH_HOMEPAGE_URL = isProd() ? 'https://www.guhealth.com.au/' : 'https://www.guhealth-test.com/';

export const NIB_BASE_COOKIE_DOMAIN = isProd() ? '.nib.com.au' : '.nib-cf-test.com';

export const ARHIPageList: ARHIFunnelPage[] = [
  {
    name: 'Welcome',
    title: 'Welcome',
    mobileTitle: 'Welcome',
    link: '/welcome',
    stepOrder: 0
  },
  {
    name: 'Hospital',
    title: 'Hospital',
    mobileTitle: 'Hospital',
    link: '/hospital',
    stepOrder: 1
  },
  {
    name: 'Extras',
    title: 'Extras',
    mobileTitle: 'Extras',
    link: '/extras',
    stepOrder: 2
  },
  {
    name: 'CoverSummary',
    title: 'Cover Summary',
    mobileTitle: 'Cover Summary',
    link: '/cover-summary',
    stepOrder: 3
  },
  {
    name: 'PersonalDetails',
    title: 'You',
    mobileTitle: 'You',
    link: '/personal-details',
    stepOrder: 4
  },
  {
    name: 'FamilyDetails',
    title: 'Family',
    mobileTitle: 'Family',
    link: '/family-details',
    stepOrder: 5
  },
  {
    name: 'PartnerDetails',
    title: 'Partner',
    mobileTitle: 'Partner',
    link: '/family-details',
    stepOrder: 5
  },
  {
    name: 'AustralianGovernmentRebate',
    title: 'Rebate',
    mobileTitle: 'Rebate',
    link: '/australian-government-rebate',
    stepOrder: 6
  },
  {
    name: 'PaymentDetails',
    title: 'Payment',
    mobileTitle: 'Pay',
    link: '/payment-details',
    stepOrder: 7
  },
  {
    name: 'Complete',
    title: 'Confirmation',
    mobileTitle: 'Confirmation',
    link: '/complete',
    stepOrder: 8
  }
];

export const CORPORATE_LANDING: ARHIPage = {
  name: 'Corporate Landing',
  title: 'Corporate Landing',
  mobileTitle: 'Corporate Landing',
  link: '/corporate-landing',
  stepOrder: 0
};

export const JWTExpiredErrorPageLink = '/resume/expired/jwt';
export const QuoteExpiredErrorPageLink = '/resume/expired';

export enum ARHIPageIndex {
  WELCOME = 0,
  HOSPITAL = 1,
  EXTRAS = 2,
  COVER_SUMMARY = 3,
  PERSONAL_DETAILS = 4,
  FAMILY_DETAILS = 5,
  PARTNER_DETAILS = 6,
  AUSTRALIAN_GOVERNMENT_REBATE = 7,
  PAYMENT_DETAILS = 8,
  COMPLETE = 9
}

export const ARHI_PAYMENT_FREQUENCIES_DISPLAY = {
  Weekly: 'Weekly',
  Fortnightly: 'Fortnightly',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  HalfYearly: 'Half Yearly',
  Yearly: 'Yearly'
};

export enum PRODUCT_TYPE {
  hospital = 'hospital',
  extras = 'extras'
}

export const DefaultInitialParameters = getDefaults(FUNNEL_NAME);

export const nibFunds = ['NIB', 'GU', 'GUHEALTH', 'IMAN'];

export const appleTitle = 'Download the nib Health Funds iPhone and iPad apps on the App Store';
export const appleLink = 'https://apps.apple.com/au/app/nib-health-insurance/id373883175';
export const androidTitle = 'Download the nib Health Funds Android app on Google Play';
export const androidLink = 'https://play.google.com/store/apps/details?id=au.com.nib&amp;hl=en';

export const MIN_ADULT_DEPENDANT_AGE = 21;
export const MAX_ADULT_DEPENDANT_AGE = (allowAdultDependantsOver25) => (allowAdultDependantsOver25 ? 31 : 25);

//PHISL-7718 Insulin pumps and Pain management with device inclusion ids
export const endingSoonInclusionsIds: number[] = [441, 449];
